<script setup>
import { ref, reactive, onMounted, onUpdated, watch } from 'vue'
import Main from './components/Main.vue'

const socialMedia = [
  { 
        "name": "Twitter Share Button",
        "url": "https://twitter.com/intent/tweet?url=https%3A%2F%2Fwww.clueresearch.com%2F",
        "srcUrl": window.location.href,
        "logo": Twitter,
        "size": "large",
        "text": "CLUE Research -- Interactive Data",
        "hashtags": [''],
    },
    {
        "name": "LinkedIn Share Button",
        "url": "https://www.linkedin.com/sharing/share-offsite/?url=https%3A%2F%2Fwww.clueresearch.com%2F",
        "srcUrl": "https://www.clueresearch.com",
        "data-url": "https://www.clueresearch.com",
        "logo": LinkedIn,

    },
    {
        "name": "Facebook Share Button",
        "url": "https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.clueresearch.com%2F&amp;src=sdkpreparse",
        "class": "fb-xfbml-parse-ignore",
        "logo": FaceBook,
    },
]

import Twitter from './assets/X_logo_2023_original.svg'
import FaceBook from './assets/social-icons/f_logo_RGB-Blue_100.png'
import LinkedIn from './assets/LinkedIn-Logos/LinkedIn-Logos/LI-In-Bug.png'

function printPDF(quality = 1) {
      const hdr = document.getElementById("header")
      const ftr = document.getElementById("footer")
      console.log(hdr)
      console.log(ftr)
      hdr.style.display = "none"
      ftr.style.display = "none"
      print()
      hdr.style.display = ""
      ftr.style.display = ""
  }

function copyText() {
    let cb = navigator.clipboard;
    cb.writeText("https://www.clueresearch.com");
    let ct = document.getElementById("copytip")
    ct.setAttribute("style", "display:block;")
    function fadeout() {
      ct.setAttribute("style", "display:none;")
    }
    setTimeout(fadeout,2000)
  }

const rhizomeOn = ref(false);
const shareOn = ref(false);

function explore() {
    if (rhizomeOn.value == false) {
      rhizomeOn.value = true
      } else if (rhizomeOn.value == true) {
          rhizomeOn.value = false
          }
  }

function share() {
    if (shareOn.value == false) {
      shareOn.value = true
      } else if (shareOn.value == true) {
          shareOn.value = false
          }
  }

watch(rhizomeOn, async() => {
    let ex = document.getElementById("explore")
    if (rhizomeOn.value == false) {
          } else if (rhizomeOn.value == true) {
              }
  })

watch(shareOn, async() => {
    let ex = document.getElementById("share")
    if (shareOn.value == false) {
          } else if (shareOn.value == true) {
              }
  })

</script>

<template>
  <div id="header">
    <div class="title-box" >
      <div id="logo">
          <a href="https://www.clueresearch.com"><img src="/static/cluelogo_main.svg" class="logo" alt="logo" /></a>
      </div>
    </div>
    <div id="infoMod">
      <div id="socialLinks" v-show="rhizomeOn" >
          <!-- <a href="" target="_blank" ><img class="socialLinks" src="/static/youtube.svg" /></a> -->
          <!-- <a href="" target="_blank" ><img class="socialLinks" src="/static/facebook.svg" /></a> -->
          <a href="https://www.linkedin.com/company/clue-research" target="_blank" ><img class="socialLinks" src="/static/linkedin.svg" /></a>
          <!-- <a href="" target="_blank" ><img class="socialLinks" src="/static/instagram.svg" /></a> -->
          <a href="https://github.com/semiographics" target="_blank" ><img class="socialLinks" src="/static/github.svg" /></a>
      </div>
      <button id="explore" @click="explore">
          <img class="explore" src="/static/list.svg" />
      </button>
    </div>
  </div>
  <!-- <div id="construction">
    <img src="/static/underconstruction.png" />
    <h1><b><i>Under Construction</i></b></h1>
    <h1><b><i>Coming Soon!</i></b></h1>
  </div> -->
  <Main />
  <footer id="footer">
    <div id="shareBlock">
        <div id="shareLinks" v-show="shareOn" >
          <!-- <button id="print2pdf" class="share-btn" @click="printPDF()">
              <img title="Print to PDF" class="share-img" src="/static/filetype-pdf.svg" />
          </button> -->
          <button class="share-btn" :id=account.name v-for="account in socialMedia" >
              <a :class=account.class :href=account.url target="_blank"
                  :data-text=account.text :data-size=account.size
                  :data-hashtags=account.hashtags :data-url=account.srcUrl
                  >
                  <img svg-inline :src=account.logo class="share-img">
              </a>
          </button>
          <button class="share-btn" type="button" @click="copyText">
              <img title="Copy Link" svg-inline class="share-img" src="/static/link.svg" />
          </button>
        </div>
        <button id="share" @click="share">
              <img class="share-icon" src="/static/share-fill.svg" />
        </button>
    </div>
  </footer>
</template>

<style scoped>

/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

body {
  --color-primary-1: #8f3d58 ;/* Main Secondary color (1) */
  --color-secondary-1: #2b6597 ;/* Main Primary color */
  --color-complement-1a: #917543 ;/* Main Secondary color (2) */
  --color-complement-1b: #5a6722 ;	/* Main Complement color */
  --color-primary-2: #B34745;
  --color-secondary-2: #2c689c ;
  --color-complement-2a: #98a829 ;
  --color-complement-2b: #209e3d ;
  --color-background-1: #181818 ;
  --color-background-2: #444444 ;
  --color-background-3: #666666 ;
  --color-icon-background-1: white;
}

#header {
  position:fixed;
  top:0;
  left:0;
  display: -ms-grid;
  display: grid;
  background-color: white;
  -ms-grid-columns: auto auto;
  grid-template-columns: auto auto;
  list-style-type: none;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-line-pack: left;
      align-content: left;
  z-index: 10;
  color: #1f0970;
  width: 98%;
  height: max(5vw,5em);
  max-height: max(15vw);
  vertical-align: middle;
  padding-right: 1%;
  padding-left: 1%;
  /* padding-top: 0.5%; */
}

.title-box {
  display:-ms-grid;
  display:grid;
  -ms-grid-columns: auto auto;
  grid-template-columns: auto auto;
  float: left;
}

.logo {
  will-change: filter;
  -webkit-transition: -webkit-filter 300ms;
  transition: -webkit-filter 300ms;
  -o-transition: filter 300ms;
  transition: filter 300ms;
  transition: filter 300ms, -webkit-filter 300ms;
  height: max(5vw,5em);
  max-height: max(12vw);
}

#infoMod {
  height: max(5vw,5em);
  max-height: max(12vw);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

button#explore, button#share {
    background-color: transparent;
    line-height: 0;
    border: none;
    stroke: none;
}

body {
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  place-items: center;
  min-height: 100vh;
  width: 100vw;
}

#construction {
  padding-top: max(5vw,5em);
  background-color: #5a6722;
  height: calc(100% - max(5vw,5em));
  width: 40%;
  margin-left: 30%;
}

#construction>img {
  padding-top: min(5em,15vw);
  height: 100%;
}

#footer {
  text-align: center;
  dominant-baseline: middle;
  position:fixed;
  bottom:0;
  right:0;
  display: -ms-grid;
  display: grid;
  z-index: 10;
  width: 98%;
  height: max(5vw,5em);
  max-height: max(15vw);
  vertical-align: middle;
  padding-right: 1%;
  padding-left: 1%;
  /* padding-bottom: 0.5%; */
  background-color: white;
}

#copytip {
  font-size: min(4em,2vw);
  position: absolute;
  bottom: max(2vw,2em);
  right: 2%;
  display: none;
  background-color: #181818;
}

#footer>p {
  font-size: min(0.7em,7pt);
}

#support {
  font-size: min(0.8em);
  float: left;
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 0.5em;
}

#socialLinks {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  vertical-align: middle;
  float:right;
}

#shareLinks {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  vertical-align: middle;
  float:right;
}

#shareBlock {
  position: fixed;
  right: 0;
  bottom: 0;
  height: max(5vw,5em);
  max-height: max(12vw);
  /* padding-bottom: 0.5%; */
  padding-right: 1%;
  padding-left: 1%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.socialLinks {
  width: max(2vw,2em);
  max-width: max(6vw);
  padding-right: max(0.5vw,0.4em);
  padding-left: max(0.5vw,0.4em);
  vertical-align: middle;
  
}

.share-img {
  width: max(3vw,3em);
  max-width: max(9vw);
  padding-right: max(0.5vw,0.2em);
  padding-left: max(0.5vw,0.2em);
  background-color: transparent;
}

.share-btn {
  line-height: 0;
  background-color: white;
}

.explore {
    text-align: center;
    height: max(4vw,3em);
    max-height: max(9.6vw);
    width: max(4vw,3em);
    max-width: max(9.6vw);
}
.share-icon {
    text-align: center;
    height: max(4vw,3em);
    max-height: max(9.6vw);
    width: max(4vw,3em);
    max-width: max(9.6vw);
}

h1 {
  line-height: 1.1;
}

button {
  border-radius: 8px;
  border: none;
  font-size: 1em;
  font-weight: 500;
  font-family: inherit;
  cursor: pointer;
  -webkit-transition: border-color 0.25s;
  -o-transition: border-color 0.25s;
  transition: border-color 0.25s;
}
button:hover {
  border-color: #646cff;
}

button:active {
  -webkit-box-shadow: 2px 2px 5px var(--color-complement-2b);
          box-shadow: 2px 2px 5px var(--color-complement-2b);
}

@media (max-aspect-ratio: 1/1) {
      #footer {
        background-color: white;
        }
      #header, #footer {
        width: 98%;
      }
      #copytip {
        left: 0;
        bottom: 50%;
      }
    }

</style>
