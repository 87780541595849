<template>
    <div id="feature">
        <div id="welcome">
            <div id="projectList">
                <h1>Project List</h1>
                <button class="projects" :id=link.name v-for="link in linkList" >
                    <a class="links" :href=link.url target="_blank">
                        {{ link.text }}
                    </a>
                </button>
            </div>
            <div id="featuredImage">
                <h1>Featured</h1>
                <img class="imageGallery" src="/src/assets/mcts_accessibility.png">
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, reactive, onMounted, onUpdated, watch, computed } from 'vue'

const linkList = [
    {
        "name": "PCMMH",
        "url": "https://www.mmhmap.com",
        "text": "Policy Center for Maternal Mental Health: Risk/Resources Map"
    },
    {
        "name": "PCMMH",
        "url": "https://state-report-cards.mmhmap.com",
        "text": "Policy Center for Maternal Mental Health: 2023 State Report Cards"
    },
    {
        "name": "HCMA",
        "url": "https://www.hcmeducation.com",
        "text": "Hypertrophic Cardiomyopathy Association: 2023 State Report Cards"
    },
    {
        "name": "OCAF-Data",
        "url": "https://www.clueresearch.com/api/ocaf",
        "text": "HUD OCAF Data"
    },
    {
        "name": "Chicago-FHVs",
        "url": "https://www.clueresearch.com/tableau/chicago-FHV-industry-2022",
        "text": "The State of the Chicago FHV Industry, 2022"
    },
    {
        "name": "Data-Viz",
        "url": "https://www.clueresearch.com/viz/portfolio",
        "text": "Other Data Visualization Projects"
    },
]

</script>

<style scoped>

/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

#feature {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  width: calc(100% - 2rem);
  height: 85dvh;
  padding-top: max(6vw,6em);
  --color-primary-1: #8f3d58 ;/* Main Secondary color (1) */
  --color-secondary-1: #2b6597 ;/* Main Primary color */
  --color-complement-1a: #917543 ;/* Main Secondary color (2) */
  --color-complement-1b: #5a6722 ;	/* Main Complement color */
  --color-primary-2: #B34745;
  --color-secondary-2: #2c689c ;
  --color-complement-2a: #98a829 ;
  --color-complement-2b: #209e3d ;
  --color-background-1: #181818 ;
  --color-background-2: #444444 ;
  --color-background-3: #666666 ;
  --color-icon-background-1: white;
}

#welcome {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

#projectList {
    display: grid;
    grid-template-rows: 0.2fr 1fr 1fr 1fr 1fr 1fr 1fr;
    height: calc(100% - max(5vw,5em));
    width: 100%;
    /* padding-left: 10%; */
}

#featuredImage {
    /* width: 50%; */
}

.projects {
    background-color: transparent;
    border: none;
}

.links {
    font-size: 1.8em;
    color: var(--color-primary-1);
}

.imageGallery {
    width: 80%;
}

@media (max-aspect-ratio: 1/1) {
    #feature {
        width: calc(100% - 0rem);
        height: 100%;
        padding-top: max(5vw,5em);
        padding-bottom: max(5vw,5em);
    }

    #welcome {
        display: grid;
        grid-template-columns: none;
        grid-auto-rows: 1.5fr 1fr;
        /* grid-auto-flow: row; */
    }

    .links {
        font-size: 1.5em;
        color: var(--color-primary-1);
    }
}

</style>